import { 
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/storageTerminalCost', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.storageTerminal.options = ctx.storageTerminalOptions
  fieldsById.storageCostUnit.options = ctx.storageCostUnitOptions
}

export default service
