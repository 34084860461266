<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import {
  CargillCrudMetaView,
  responseToOptionsInContext
} from '@cargill/shared'
import service from '../api/storageTerminalService'
import storageTerminalInformationService from '../api/storageTerminalInformationService'
import storageTerminalCostService from '../api/storageTerminalCostService'
import storageTerminalTakeOrPayCostService from '../api/storageTerminalTakeOrPayCostService'
import storageTerminalVolumeTakeOrPayService from '../api/storageTerminalVolumeTakeOrPayService'
import _ from 'lodash'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getStorageTerminalCostMeta() {
      const storageTerminalCostMeta =
        await storageTerminalCostService.getMetaWithValidation()
      storageTerminalCostMeta.name = 'storageTerminalCost'
      storageTerminalCostMeta.masterName = 'storageTerminalId'
      storageTerminalCostMeta.fields = storageTerminalCostMeta.fields.filter(
        (field) => field.id != 'storageTerminal'
      )
      storageTerminalCostMeta.detailsObject = {
        service: storageTerminalCostService
      }

      return storageTerminalCostMeta
    },
    async getStorageTerminalInformationMeta() {
      const storageTerminalInformationMeta =
        await storageTerminalInformationService.getMetaWithValidation()
      storageTerminalInformationMeta.name = 'storageTerminalInformation'
      storageTerminalInformationMeta.masterName = 'storageTerminalId'
      storageTerminalInformationMeta.fields =
        storageTerminalInformationMeta.fields.filter(
          (field) => field.id != 'storageTerminal'
        )
      storageTerminalInformationMeta.detailsObject = {
        service: storageTerminalInformationService
      }

      return storageTerminalInformationMeta
    },
    async getStorageTerminalTakeOrPayCostMeta() {
      const storageTerminalTakeOrPayCostMeta =
        await storageTerminalTakeOrPayCostService.getMetaWithValidation()
        storageTerminalTakeOrPayCostMeta.name = 'storageTerminalTakeOrPayCost'
     storageTerminalTakeOrPayCostMeta.masterName = 'storageTerminalId'
     storageTerminalTakeOrPayCostMeta.fields =
     storageTerminalTakeOrPayCostMeta.fields.filter(
          (field) => field.id != 'storageTerminal'
        )
        storageTerminalTakeOrPayCostMeta.detailsObject = {
        service: storageTerminalTakeOrPayCostService
      }

      return storageTerminalTakeOrPayCostMeta
    },
    //
    async getStorageTerminalVolumeTakeOrPayMeta() {
      const storageTerminalVolumeTakeOrPayMeta =
        await storageTerminalVolumeTakeOrPayService.getMetaWithValidation()
        storageTerminalVolumeTakeOrPayMeta.name = 'storageTerminalVolumeTakeOrPay'
     storageTerminalVolumeTakeOrPayMeta.masterName = 'storageTerminalId'
     storageTerminalVolumeTakeOrPayMeta.fields =
     storageTerminalVolumeTakeOrPayMeta.fields.filter(
          (field) => field.id != 'storageTerminal'
        )
        storageTerminalVolumeTakeOrPayMeta.detailsObject = {
        service: storageTerminalVolumeTakeOrPayService
      }

      return storageTerminalVolumeTakeOrPayMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getStorageTerminalInformationMeta(),
        this.getStorageTerminalCostMeta(),
        this.getStorageTerminalTakeOrPayCostMeta(),
        this.getStorageTerminalVolumeTakeOrPayMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      const fieldsById = _.keyBy(meta.fields, (f) => f.id)
      fieldsById.terminalCenter.options = ctx.terminalCenterOptions

      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      meta.details.forEach((detail) => {
        this.details[detail.name] = detail.detailsObject
      })
      this.metadata = meta
    })
  }
}
</script>
